<template>
  <AppPreview />
</template>

<script>
  import AppPreview from '@/components/AppPreview'

  export default {
    name: 'Home',

    components: {
      AppPreview
    },
  }
</script>
