<template>
  <div style="display: block; height: 60px" v-ripple>
    <img :src="image" @click="click" class="pa-2" style="cursor: pointer; max-width: 60px; max-height: 60px;">
    <modal :name="'modal-header-menu-button-' + id" :adaptive="true" :width="720" :max-width="720" height="auto">
      <div class="pa-4 ma-4">
        <v-file-input accept="image/*" label="画像" @change="changeImage" :value="file"></v-file-input>
        <v-text-field
          name="URL"
          label="リンク(URL)"
          prepend-icon="mdi-link"
          :value="url"
          @change="changeURL"
        ></v-text-field>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import loadImage from 'blueimp-load-image';

export default Vue.extend({
  name: "HeaderMenuButton",
  props: {
    id: {
      type: Number,
      default: -1
    },
    image: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    onchanged: {
      type: Function,
    }
  },
  data: () => ({
    file: null
  }),
  methods: {
    click() {
      this.$modal.show('modal-header-menu-button-' + this.id);
    },
    changeURL(url) {
      this.onchanged(this.id, this.image, url);
    },
    changeImage(file) {
      this.file = file;
      loadImage(
        file,
        async (canvas) => {
          const data = canvas.toDataURL(file.type);
          const bin = atob(data.replace(/^.*,/, ''));
          const buffer = new Uint8Array(bin.length);
          for (let i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
          }
          const blob =  new Blob([buffer.buffer], {
            type: file.type ? file.type : 'image/png'
          });
          const url = window.URL.createObjectURL(blob);
          this.onchanged(this.id, url, this.url);
        },
        { canvas: true }
      );
    },
  },
});
</script>
