var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],style:({
    width: _vm.maxwidth + 'px',
    height: _vm.maxheight + 'px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    flexWrap: 'wrap',
    alignContent: 'center',
    cursor: 'pointer',
    }),on:{"click":_vm.click}},[_c('img',{staticClass:"tile-button-image",attrs:{"src":_vm.image}}),_c('p',{staticClass:"tile-button-text"},[_vm._v(_vm._s(this.text))])]),_c('modal',{attrs:{"name":'modal-tile-button-' + _vm.id,"adaptive":true,"width":720,"max-width":720,"height":"auto"}},[_c('div',{staticClass:"pa-4 ma-4"},[_c('v-file-input',{attrs:{"accept":"image/*","label":"アイコン","value":_vm.file},on:{"change":_vm.changeImage}}),_c('v-text-field',{attrs:{"name":"text","label":"テキスト","prepend-icon":"mdi-text","value":_vm.text},on:{"change":_vm.changeText}}),_c('v-text-field',{attrs:{"name":"URL","label":"リンク(URL)","prepend-icon":"mdi-link","value":_vm.url},on:{"change":_vm.changeURL}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }