<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <h1>shopapp-prepare</h1>
      </div>

      <v-spacer></v-spacer>

      <v-btn icon @click="help">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>

    <modal name="modal-help" :scrollable="true" :width="720" :max-width="720" height="auto" class="modal">
      <div id ='write'>
        <h2 id='操作方法'><span>操作方法</span></h2><h3 id='①-オプション'><span>①: オプション</span></h3><ol start='' ><li><p><span>データ復元</span>
        <span>shopapp-prepareを以前の状態に復元します。</span>
        <span>復元には③でダウンロードしたzipファイルが必要です。</span></p></li><li><p><span>アプリ基本情報</span></p><ol start='' ><li><p><span>アプリ名</span>
        <span>アプリの表示名を設定します。</span></p></li><li><p><span>BundleID</span>
        <span>他のアプリと重複することのないアプリ固有のIDを設定します。</span>
        <code>com.企業名.アプリ名</code><span> という形式が一般的です。</span>
        <span>※アプリ名から生成にチェックを入れると、アプリ名のアルファベットを使用して生成します。アプリ名にアルファベットが含まれていない場合は使用できません。</span>
        <span>※半角の小文字アルファベットと数字のみ使用可能です。</span>
        <span>※記号、末尾および先頭に</span><code>.</code><span>を含めることはできません。</span>
        <span>※他のアプリと重複している場合は使用できない可能性があります。</span></p></li><li><p><span>メニュー数設定</span></p><ol start='' ><li><span>ヘッダー(上部右上)メニューボタン数</span>
        <span>アプリの右上にあるメニューのボタン数を変更できます。</span>
        <span>このメニューはコンテンツを開いている場合も常に表示されます。</span>
        <span>ユーザ情報を表示する際やお知らせボタンなどとして使用できます。</span></li><li><span>タイルメニューボタン数 (横/縦)</span>
        <span>アプリ中央下にあるメニューのボタン数を変更できます。</span>
        <span>横の数を変更すると横に並ぶボタン数、縦の数を変更すると縦に並ぶボタンの数が変更されます。</span>
        <span>ユーザに提供するメインコンテンツを配置するのに最適です。</span></li><li><span>メイン(ボトム)メニューボタン数</span>
        <span>アプリの下部にあるメニューのボタン数を変更できます。</span>
        <span>このメニューはコンテンツを開いている場合も常に表示されます。</span>
        <span>ユーザが手軽にアクセス可能な項目に最適です。</span></li><li><span>サブ(サイド)メニューボタン数</span>
        <span>アプリの左上にあるハンバーガーメニュー(≡)をタップもしくは画面左部からスライドした際に表示されるサブメニューのボタン数を変更できます。</span>
        <span>このメニューはコンテンツを開いている場合も常に表示可能です。</span></li></ol></li><li><p><span>テーマカラー</span></p><ol start='' ><li><span>プライマリ</span>
        <span>アプリのヘッダー部分や読み込み時の背景などに使われる背景色を指定可能です。</span></li><li><span>セカンダリ</span>
        <span>アプリのフッター部分やサブメニューの背景などに使われる背景色を指定可能です。</span></li></ol></li><li><p><span>テキストカラー</span></p><ol start='' ><li><span>プライマリ</span>
        <span>タイルメニューなどで使われる文字色を指定可能です。</span></li><li><span>セカンダリ</span>
        <span>メインメニューやサブメニューなどで使われる文字色を指定可能です。</span></li><li><span>選択時</span>
        <span>メインメニューの選択時に使われる文字色を指定可能です。</span></li></ol></li></ol></li></ol><h3 id='②-プレビュー'><span>②: プレビュー</span></h3><p><span>各ボタンをクリックすることで画像やテキスト、リンクなどの設定が可能です。</span>
        <span>サブメニューは左上にあるハンバーガーメニュー(≡)をクリックすると表示されます。</span></p><p><span>画像やアイコンはサイズが決められているので、それに沿って画像を設定してください。</span>
        <span>(画像の比率が一致していれば動作は可能です)</span></p><h3 id='③-ダウンロード'><span>③: ダウンロード</span></h3><p><span>アプリのデータをダウンロードします。</span>
        <span>ここでダウンロードしたデータを提出いただくことで実際の端末上で動作するアプリの作成が可能となります。</span>
        <span>また、このデータは①オプションのデータ復元から指定することで、ページを閉じた後も復元可能です。</span></p>
      </div>
    </modal>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  }),
  methods: {
    help() {
      this.$modal.show('modal-help');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  div {
    margin: 4px;
    padding: 32px 48px;
  }
  h1, h2 {
    padding: 4px 2px;
    text-align: center;
  }
  h3 {
    padding: 24px 2px 4px
  }
}
#write code,
#write span {
  display: inline-block;
}
#write li {
  padding: 4px 0;
}
</style>