<template>
  <div>
    <div color="success" v-ripple @click="click" style="cursor:pointer;display:flex;">
      <img :src="image" class="sub-menu-button-image">
      <p class="sub-menu-button-text">{{ this.text }}</p>
    </div>
    <modal :name="'modal-sub-menu-button-' + id" :adaptive="true" :width="720" :max-width="720" height="auto">
      <div class="pa-4 ma-4">
        <v-file-input accept="image/*" label="アイコン" @change="changeImage" :value="file"></v-file-input>
        <v-text-field
          name="text"
          label="テキスト"
          prepend-icon="mdi-text"
          :value="text"
          @change="changeText"
        ></v-text-field>
        <v-text-field
          name="URL"
          label="リンク(URL)"
          prepend-icon="mdi-link"
          :value="url"
          @change="changeURL"
        ></v-text-field>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import loadImage from 'blueimp-load-image';

export default Vue.extend({
  name: "SubMenuButton",
  props: {
    image: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'TEXT'
    },
    url: {
      type: String,
      default: ''
    },
    id: {
        type: Number,
        default: -1,
    },
    onchanged: {
      type: Function,
    }
  },
  data: () => ({
    file: null,
  }),
  methods: {
    click() {
      this.$modal.show('modal-sub-menu-button-' + this.id);
    },
    changeText(text) {
      this.onchanged(this.id, this.image, text, this.url);
    },
    changeURL(url) {
      this.onchanged(this.id, this.image, this.text, url);
    },
    changeImage(file) {
      this.file = file;
      loadImage(
        file,
        async (canvas) => {
          const data = canvas.toDataURL(file.type);
          const bin = atob(data.replace(/^.*,/, ''));
          const buffer = new Uint8Array(bin.length);
          for (let i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
          }
          const blob =  new Blob([buffer.buffer], {
            type: file.type ? file.type : 'image/png'
          });
          const url = window.URL.createObjectURL(blob);
          this.onchanged(this.id, url, this.text, this.url);
        },
        { canvas: true }
      );
    },
  },
});
</script>

<style scoped>
.sub-menu-button-image {
  display: block;
  margin: auto;
  max-width: 60px;
  width: 66%;
  padding: 8px 14px !important;
}
.sub-menu-button-text {
  margin: 0;
  width: 100%;
  font-size: 1.1em;
  line-height: 60px;
  text-align: left;
  padding: 0 10px;
}
</style>
