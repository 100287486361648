<template>
  <div>
    <div :style="{
      width: maxwidth + 'px',
      height: maxheight + 'px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'relative',
      flexWrap: 'wrap',
      alignContent: 'center',
      cursor: 'pointer',
      }" v-ripple @click="click">
      <img :src="image" class="tile-button-image">
      <p class="tile-button-text">{{ this.text }}</p>
    </div>
    <modal :name="'modal-tile-button-' + id" :adaptive="true" :width="720" :max-width="720" height="auto">
      <div class="pa-4 ma-4">
        <v-file-input accept="image/*" label="アイコン" @change="changeImage" :value="file"></v-file-input>
        <v-text-field
          name="text"
          label="テキスト"
          prepend-icon="mdi-text"
          :value="text"
          @change="changeText"
        ></v-text-field>
        <v-text-field
          name="URL"
          label="リンク(URL)"
          prepend-icon="mdi-link"
          :value="url"
          @change="changeURL"
        ></v-text-field>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import loadImage from 'blueimp-load-image';

export default Vue.extend({
  name: "TileMenuButton",
  props: {
    maxwidth: {
      type: Number,
      default: 496/3,
    },
    maxheight: {
      type: Number,
      default: 496/3,
    },
    id: {
        type: Number,
        default: -1,
    },
    image: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'TEXT'
    },
    url: {
      type: String,
      default: ''
    },
    onchanged: {
      type: Function,
    }
  },
  data: () => ({
    file: null,
    modalWidth: 720
  }),
  methods: {
    click() {
      this.$modal.show('modal-tile-button-' + this.id);
    },
    changeText(text) {
      this.onchanged(this.id, this.image, text);
    },
    changeURL(url) {
      this.onchanged(this.id, this.image, this.text, url);
    },
    changeImage(file) {
      this.file = file;
      loadImage(
        file,
        async (canvas) => {
          const data = canvas.toDataURL(file.type);
          const bin = atob(data.replace(/^.*,/, ''));
          const buffer = new Uint8Array(bin.length);
          for (let i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
          }
          const blob =  new Blob([buffer.buffer], {
            type: file.type ? file.type : 'image/png'
          });
          const url = window.URL.createObjectURL(blob);
          this.onchanged(this.id, url, this.text);
        },
        { canvas: true }
      );
    },
  },
});
</script>

<style scoped>
.tile-button-image {
  display: block;
  max-width: 35%;
}
.tile-button-text {
  margin: 0;
  width: 100%;
  font-weight: bold;
  font-size: 1.2em;
  padding-top: 6px;
}
</style>
