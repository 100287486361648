<template>
  <v-form id="app-preview" ref="mainForm" :style="{
    '--primary-text-color': colors.primaryTextColor,
    '--secondary-text-color': colors.secondaryTextColor,
    '--primary-theme-color': colors.primaryThemeColor,
    '--secondary-theme-color': colors.secondaryThemeColor,
    '--selected-text-color': colors.selectedTextColor,

    '--header-background-color': colors.headerBackgroundColor,
    '--main-menu-background-color': colors.mainMenuBackgroundColor,
    '--sub-menu-background-color': colors.subMenuBackgroundColor,
    '--tile-menu-background-color1': colors.tileMenuBackgroundColor1,
    '--tile-menu-background-color2': colors.tileMenuBackgroundColor2,

    '--tile-menu-text-color': colors.tileMenuTextColor,
    '--main-menu-text-color': colors.mainMenuTextColor,
    '--main-menu-selected-text-color': colors.mainMenuSelectedTextColor,
    '--sub-menu-text-color': colors.subMenuTextColor,
    '--sub-menu-open-icon-color': colors.subMenuOpenIconColor,
    }">
    <v-container>
      <v-container id="option">
        <h2 class="text-center ma-4">オプション</h2>
        <v-container>
          <v-col>
            <v-col>
              <v-row>
                <h3 class="text-center py-4">データ復元</h3>
                <v-file-input accept="application/zip" label="ファイルから復元 (*.zip)" @change="restore"></v-file-input>
              </v-row>
            </v-col>

            <v-col>
              <h3 class="text-center py-4">アプリ基本情報</h3>
              <v-row class="text-center my-2" style="display:block;">
                <label for="app-icon">アプリアイコン<br>(透明無効)</label>
                <ImageButton id="app-icon" :defaultwidth="128" :defaultheight="128" :image="appIcon" :name="'appIcon'" :onchanged="changeAppIcon" :backgroundcolor="'#000'"></ImageButton>
                <p>{{ appname }}</p>
              </v-row>

              <v-row class="text-center ma-2">
                <v-text-field
                  name="appfullname"
                  label="フルアプリ名 (ストア表示名)"
                  placeholder="example app 公式アプリ"
                  v-model="appfullname"
                  :rules="[() => !!appfullname || 'このフィールドは必須項目です']"
                ></v-text-field>
              </v-row>

              <v-row class="text-center ma-2">
                <v-text-field
                  name="appname"
                  label="アプリ名 (アイコン表示名)"
                  placeholder="example アプリ"
                  v-model="appname"
                  :rules="[() => !!appname || 'このフィールドは必須項目です']"
                ></v-text-field>
              </v-row>

              <v-row class="text-center ma-2">
                <v-text-field
                  name="appnameen"
                  label="英語アプリ名 (内部アプリID: 半角英数字とハイフンのみ)"
                  placeholder="example app"
                  v-model="appnameen"
                  @input="onChangeAppname"
                  :rules="[
                  () => !!appnameen || 'このフィールドは必須項目です',
                  () => !!appnameen && (/^[a-zA-Z0-9\-]*$/.test(appnameen)) || '全角文字またはその他の特殊文字を含めることはできません',
                  ]"
                ></v-text-field>
              </v-row>

              <v-row align="center" class="text-center ma-2">
                <v-text-field
                  name="bundleid"
                  label="BundleID (外部アプリID: 半角小文字英数字と.のみ)"
                  placeholder="com.example.app (com.団体名.アプリ名)"
                  v-model="bundleid"
                  dense
                  :disabled="autoBundleID"
                  :rules="[
                  () => !!bundleid || 'このフィールドは必須項目です',
                  () => !!bundleid && (/^[a-z0-9\.]+$/g.test(bundleid)) || '大文字または記号･その他の特殊文字を含めることはできません',
                  () => !!bundleid && !(/^.*\.$/.test(bundleid)) || '末尾に.を含めることはできません',
                  () => !!bundleid && !(/^\..*$/.test(bundleid)) || '先頭に.を含めることはできません'
                  ]"
                ></v-text-field>
                <v-checkbox
                  v-model="autoBundleID"
                  hide-details
                  dense
                  @change="enableAutoBundleID"
                  class="shrink mr-2 mt-0"
                  label="英語アプリ名から生成"
                ></v-checkbox>
              </v-row>

              <v-row class="text-center ma-2">
                <v-text-field
                  name="siteurl"
                  label="サイトURL (一部機能の利用にはshopappプラグインが必要です)"
                  placeholder="https://example.com/"
                  v-model="siteurl"
                  :rules="[
                  () => (siteurl != null && siteurl.length > 0 && !(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(siteurl))) ? '正しいURLではありません' : true,
                  ]"
                ></v-text-field>
              </v-row>
            </v-col>

            <v-col>
              <h3 class="text-center py-4">メニュー数設定</h3>
              <v-row class="text-center ma-2">
                <v-text-field
                  name="header-menu-button-count"
                  type="number"
                  label="ヘッダー(上部右上)メニューボタン数"
                  style="width:100%;"
                  v-model="headerMenuButtonCount"
                  @input="changeHeaderMenuButtonCount">
                  <template slot="append-outer">
                    <v-icon @click="--headerMenuButtonCount;changeHeaderMenuButtonCount();">mdi-minus</v-icon>
                    <v-icon @click="++headerMenuButtonCount;changeHeaderMenuButtonCount();">mdi-plus</v-icon>
                  </template>
                </v-text-field>

                <v-row>
                  <v-col>
                    <v-text-field
                      name="tile-menu-button-count"
                      type="number"
                      label="タイルメニューボタン数 (横)"
                      v-model="tileMenuRows"
                      @input="changeTileMenuButtonCount">
                      <template slot="append-outer">
                        <v-icon @click="--tileMenuRows;changeTileMenuButtonCount();">mdi-minus</v-icon>
                        <v-icon @click="++tileMenuRows;changeTileMenuButtonCount();">mdi-plus</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      name="tile-menu-button-count"
                      type="number"
                      label="タイルメニューボタン数 (縦)"
                      v-model="tileMenuColumns"
                      @input="changeTileMenuButtonCount">
                      <template slot="append-outer">
                        <v-icon @click="--tileMenuColumns;changeTileMenuButtonCount();">mdi-minus</v-icon>
                        <v-icon @click="++tileMenuColumns;changeTileMenuButtonCount();">mdi-plus</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-text-field
                  name="main-menu-button-count"
                  type="number"
                  label="メイン(ボトム)メニューボタン数"
                  style="width:100%;"
                  v-model="mainMenuButtonCount"
                  @input="changeMainMenuButtonCount">
                  <template slot="append-outer">
                    <v-icon @click="--mainMenuButtonCount;changeMainMenuButtonCount();">mdi-minus</v-icon>
                    <v-icon @click="++mainMenuButtonCount;changeMainMenuButtonCount();">mdi-plus</v-icon>
                  </template>
                </v-text-field>

                <v-text-field
                  name="sub-menu-button-count"
                  type="number"
                  label="サブ(サイド)メニューボタン数"
                  style="width:100%;"
                  v-model="subMenuButtonCount"
                  @input="changeMainMenuButtonCount">
                  <template slot="append-outer">
                    <v-icon @click="--subMenuButtonCount;changeSubMenuButtonCount();">mdi-minus</v-icon>
                    <v-icon @click="++subMenuButtonCount;changeSubMenuButtonCount();">mdi-plus</v-icon>
                  </template>
                </v-text-field>
              </v-row>
            </v-col>
          </v-col>

          <v-col>
            <v-row class="text-center ma-2" style="position:relative;">
              <h3 class="text-center py-4">カラー設定</h3>
              <v-switch
                v-model="advancedColorMode"
                label="詳細設定"
                class="color-settings-toggle"
              ></v-switch>
            </v-row>

            <v-row>
              <h4 class="text-center py-4">背景カラー</h4>
              <div class="color-flex-box">
                <div v-for="setting in baseBackgroundColorSettings" :key="setting.id">
                  <v-label :for="setting.id">{{ setting.title }}<br><small>{{ setting.description }}</small></v-label>
                  <v-color-picker
                    :id="setting.id"
                    dot-size="25"
                    mode="rgba"
                    canvas-height="100"
                    width="230px"
                    v-model="colors[setting.id]"
                    @input="setting.onChanged"
                  ></v-color-picker>
                </div>
              </div>

              <transition name="fade">
                <div v-if="advancedColorMode">
                  <h4 class="text-center py-4">詳細背景カラー</h4>
                  <div class="color-flex-box">
                    <div v-for="setting in advancedBackgroundColorSettings" :key="setting.id">
                      <v-label :for="setting.id">{{ setting.title }}</v-label>
                      <v-color-picker
                        :id="setting.id"
                        dot-size="25"
                        mode="rgba"
                        canvas-height="100"
                        width="230px"
                        v-model="colors[setting.id]"
                      ></v-color-picker>
                    </div>
                  </div>
                </div>
              </transition>

              <h4 class="text-center py-4">テキストカラー</h4>
              <div class="color-flex-box">
                <div v-for="setting in baseTextColorSettings" :key="setting.id">
                  <v-label :for="setting.id">{{ setting.title }}<br><small>{{ setting.description }}</small></v-label>
                  <v-color-picker
                    :id="setting.id"
                    dot-size="25"
                    mode="rgba"
                    canvas-height="100"
                    width="230px"
                    v-model="colors[setting.id]"
                    @input="setting.onChanged"
                  ></v-color-picker>
                </div>
              </div>

              <transition name="fade">
                <div v-if="advancedColorMode">
                  <h4 class="text-center py-4">詳細テキスト/アイコンカラー</h4>
                  <div class="color-flex-box">
                    <div v-for="setting in advancedTextColorSettings" :key="setting.id">
                      <v-label :for="setting.id">{{ setting.title }}</v-label>
                      <v-color-picker
                        :id="setting.id"
                        dot-size="25"
                        mode="rgba"
                        canvas-height="100"
                        width="230px"
                        v-model="colors[setting.id]"
                      ></v-color-picker>
                    </div>
                  </div>
                </div>
              </transition>

            </v-row>
          </v-col>

        </v-container>
      </v-container>

      <v-container id="preview" class="js-container">
        <h2 class="text-center ma-4" style="color:unset;">プレビュー</h2>

        <div class="js-sidebar" style="border: 1px solid #aaaaaa;">
          <div style="position:relative;">
          <v-container id="preview-main">
            <v-row class="text-center preview-app-header" justify="center">
              <v-btn
                class="preview-app-menu-toggle-button"
                elevation="0"
                fab
                color="#00000000"
                @click="previewMain = !previewMain"
              >
                <v-icon>
                  mdi-menu
                </v-icon>
              </v-btn>
              <ImageButton :defaultwidth="180" :defaultheight="60" :image="appHeader" :name="'appHeader'" :onchanged="changeAppHeader" style="position:absolute;"></ImageButton>
              <div id="header-menu" style="display: flex;width: 100%;justify-content: flex-end;">
                <HeaderMenuButton
                v-for="data of headerMenuDataList"
                :key="data.id"
                :id="data.id"
                :image="headerMenuDataList[data.id].image"
                :url="headerMenuDataList[data.id].url"
                :onchanged="changeHeaderMenuButton"></HeaderMenuButton>
              </div>
            </v-row>

            <v-row class="text-center" justify="center">
              <ImageButton :defaultwidth="512" :defaultheight="512" :image="mainImage" :name="'mainImage'" :onchanged="changeMainImage"></ImageButton>
            </v-row>

            <v-row id="tile-menu" class="text-center" justify="center">
              <table style="border-collapse: collapse;">
                <tr v-for="i of tileMenuRows" :key="i">
                  <td v-for="j of tileMenuColumns" :key="j" :style="{ border: '1px solid #aaa', borderCollapse: 'collapse', backgroundColor: ((j + (i % 2 == 0 ? 1 : 0)) % 2 == 0) ? colors.tileMenuBackgroundColor1 : colors.tileMenuBackgroundColor2 }">
                    <TileMenuButton
                    :id="tileMenuColumns * (i - 1) + j - 1"
                    :image="tileMenuDataList[tileMenuColumns * (i - 1) + j - 1].image"
                    :text="tileMenuDataList[tileMenuColumns * (i - 1) + j - 1].text"
                    :url="tileMenuDataList[tileMenuColumns * (i - 1) + j - 1].url"
                    :onchanged="changeTileMenuButton"
                    :maxwidth="496/tileMenuColumns"
                    :maxheight="496/(tileMenuRows + 1)"></TileMenuButton>
                  </td>
                </tr>
              </table>
            </v-row>

            <v-row id="main-menu" class="text-center" justify="center">
              <MainMenuButton
              v-for="data of mainMenuDataList" :key="data.id"
              :id="data.id"
              :image="data.image"
              :text="data.text"
              :url="data.url"
              :getmainmenubuttoncount="getMainMenuButtonCount"
              :onchanged="changeMainMenuButton" />
            </v-row>
          </v-container>

          <transition name="fade">
            <v-container v-if="!previewMain" id="preview-sub-menu">
              <div class="bg-overlay" @click="previewMain = !previewMain"></div>
              <v-row class="text-center" justify="center">
              <SubMenuButton
                v-for="data of subMenuDataList" :key="data.id"
                :id="data.id"
                :image="data.image"
                :text="data.text"
                :url="data.url"
                :onchanged="changeSubMenuButton" />
              </v-row>
            </v-container>
          </transition>
        </div>
        </div>
        
      </v-container>
    </v-container>

    <hr class="my-4">

    <v-row class="text-center ma-2" justify="center" style="display: block;">
      <v-btn color="success" @click="download">ダウンロード</v-btn>
    </v-row>

    <modal id="modal-message" name='modal-message' :adaptive="true" :width="480" height="auto">
      <div class="pa-4 ma-4">
        <p style="color:red;text-align:center;">{{ message }}</p>
        <p class="modal-message-force-download-button" @click="download(false)">エラーを無視してダウンロード</p>
        <p class="modal-message-close-button" @click="hideModal">閉じる</p>
      </div>
    </modal>

  </v-form>
</template>

<script>
import Vue from "vue";
import jszip from 'jszip'
import { saveAs } from "file-saver";
import PrettyScroll from 'pretty-scroll';

import ImageButton from "@/components/ImageButton.vue"
import HeaderMenuButton from "@/components/HeaderMenuButton.vue"
import TileMenuButton from "@/components/TileMenuButton.vue"
import MainMenuButton from "@/components/MainMenuButton.vue"
import SubMenuButton from "@/components/SubMenuButton.vue"
import BitmapRenderer from '../lib/BitmapRenderer'
import SVGREnderer from '../lib/SVGRenderer'

const renderers = {
  svg: new SVGREnderer(),
  bitmap: new BitmapRenderer()
}

function getRenderParams(width, height) {
  return {
    width: width,
    height: height,
    textColor: '#999999',
    backgroundColor: '#ccc',
    caption: `${width} x ${height}`,
    fontFamily: 'sans-serif',
    fontSize: width / 6,
    fontWeight: '400',
    filetype: 'image/png'
  }
}

class ImageData {
  width;
  height;
  image;

  constructor(width, height) {
    this.width = width;
    this.height = height;
    this.image = renderers.bitmap.render(getRenderParams(width, height));
  }
  setImage(image) { this.image = image; }
}
class TileMenuData {
  id;
  image;
  text;
  url;

  constructor() {
    this.id = -1;
    this.image = renderers.bitmap.render(getRenderParams(128, 128));
    this.text = 'TEXT';
    this.url = '';
  }
  setImage(image) { this.image = image; }
  setText(text)   { this.text  = text ; }
  setURL(url)     { this.url   = url.trim(); }
  setID(id)       { this.id    = id   ; }
}
class HeaderMenuData {
  id;
  image;
  url;

  constructor() {
    this.id = -1;
    this.image = renderers.bitmap.render(getRenderParams(128, 128));
    this.url = '';
  }
  setImage(image) { this.image = image; }
  setURL(url)     { this.url   = url.trim(); }
  setID(id)       { this.id    = id   ; }
}
class MainMenuData {
  id;
  image;
  text;
  url;

  constructor() {
    this.id = -1;
    this.image = renderers.bitmap.render(getRenderParams(128, 128));
    this.text = 'TEXT';
    this.url = '';
  }
  setImage(image) { this.image = image; }
  setText(text)   { this.text  = text ; }
  setURL(url)     { this.url   = url.trim(); }
  setID(id)       { this.id    = id   ; }
}
class SubMenuData {
  id;
  image;
  text;
  url;

  constructor() {
    this.id = -1;
    this.image = renderers.bitmap.render(getRenderParams(128, 128));
    this.text = 'TEXT';
    this.url = '';
  }
  setImage(image) { this.image = image; }
  setText(text)   { this.text  = text ; }
  setURL(url)     { this.url   = url.trim(); }
  setID(id)       { this.id    = id   ; }
}

export default Vue.extend({
  name: "AppPreview",
  components: { ImageButton, HeaderMenuButton, TileMenuButton, MainMenuButton, SubMenuButton },
  data: () => ({
    appfullname: '',
    appname: '',
    appnameen: '',
    bundleid: '',
    siteurl: '',

    colors: {
      // base colors
      primaryThemeColor: '#898989',
      secondaryThemeColor: '#898989',
      primaryTextColor: '#707070',
      secondaryTextColor: '#ffffff',
      selectedTextColor: '#FFD79C',

      // detail colors [background]
      headerBackgroundColor: '#898989',
      mainMenuBackgroundColor: '#898989',
      tileMenuBackgroundColor1: '#ffffff',
      tileMenuBackgroundColor2: '#f2f2f2',
      subMenuBackgroundColor: '#898989',

      // detail colors [text]
      tileMenuTextColor: '#707070',
      mainMenuTextColor: '#ffffff',
      mainMenuSelectedTextColor: '#FFD79C',
      subMenuTextColor: '#ffffff',
      subMenuOpenIconColor: '#ffffff',
    },

    headerMenuButtonCount: 1,
    tileMenuColumns: 3,
    tileMenuRows: 2,
    mainMenuButtonCount: 5,
    subMenuButtonCount: 5,
    appIcon: new ImageData(1024, 1024),
    appHeader: new ImageData(720, 240),
    mainImage: new ImageData(512, 512),
    headerMenuDataList: [new HeaderMenuData()],
    tileMenuDataList: [new TileMenuData()],
    mainMenuDataList: [new MainMenuData()],
    subMenuDataList: [new SubMenuData()],
    previewMain: true,
    message: '',
    messageHTML: null,
    autoBundleID: true,
    advancedColorMode: false,
  }),
  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      new PrettyScroll('.js-sidebar', {
          container: '.js-container',
          breakpoint: 0,
          offsetTop: 60,
          offsetBottom: 0,
          condition: () => true
      });
    });
  },
  computed: {
    tileMenuButtonCount: {
      get() {
        return this.tileMenuColumns * this.tileMenuRows;
      }
    },
    baseBackgroundColorSettings: {
      get() {
        return [
          { id: 'primaryThemeColor', title: 'プライマリ背景カラー', description: 'アプリ起動時のスプラッシュ背景などのメインテーマカラーに使用されます', onChanged: this.onPrimaryThemeColorChanged },
          { id: 'secondaryThemeColor', title: 'セカンダリ背景カラー', description: 'ページ読み込み時のアイコンなどのサブテーマカラーに使用されます', onChanged: this.onSecondaryThemeColorChanged },
        ]
      }
    },
    advancedBackgroundColorSettings: {
      get() {
        return [
          { id: 'headerBackgroundColor', title: 'ヘッダー背景カラー' },
          { id: 'mainMenuBackgroundColor', title: 'メイン(ボトム)メニュー背景カラー' },
          { id: 'tileMenuBackgroundColor1', title: 'タイルメニュー背景カラー1' },
          { id: 'tileMenuBackgroundColor2', title: 'タイルメニュー背景カラー2' },
          { id: 'subMenuBackgroundColor', title: 'サブ(サイド)メニュー背景カラー' },
        ]
      }
    },
    baseTextColorSettings: {
      get() {
        return [
          { id: 'primaryTextColor', title: 'プライマリテキストカラー', description: 'タイルメニュー文字などのメイン文字カラーに使用されます', onChanged: this.onPrimaryTextColorChanged },
          { id: 'secondaryTextColor', title: 'セカンダリテキストカラー', description: 'サブメニュー文字などのサブ文字カラーに使用されます', onChanged: this.onSecondaryTextColorChanged },
          { id: 'selectedTextColor', title: '選択時テキストカラー', description: 'メインメニュー選択時などの選択時カラーに使用されます', onChanged: this.onSelectedTextColorChanged },
        ]
      }
    },
    advancedTextColorSettings: {
      get() {
        return [
          { id: 'tileMenuTextColor', title: 'タイルメニューテキストカラー' },
          { id: 'mainMenuTextColor', title: 'メイン(ボトム)メニューテキストカラー' },
          { id: 'mainMenuSelectedTextColor', title: 'メイン(ボトム)メニュー選択時テキストカラー' },
          { id: 'subMenuTextColor', title: 'サブ(サイド)メニューテキストカラー' },
          { id: 'subMenuOpenIconColor', title: 'サブ(サイド)メニューアイコンカラー' }
        ]
      }
    }
  },
  methods: {
    hideModal() {
      this.$modal.hide('modal-message');
    },
    onPrimaryThemeColorChanged(color) {
      if (!this.advancedColorMode) {
        this.colors.headerBackgroundColor = color;
      }
    },
    onSecondaryThemeColorChanged(color) {
      if (!this.advancedColorMode) {
        this.colors.mainMenuBackgroundColor = this.colors.subMenuBackgroundColor = color;
      }
    },
    onPrimaryTextColorChanged(color) {
      if (!this.advancedColorMode) {
        this.colors.tileMenuTextColor = color;
      }
    },
    onSecondaryTextColorChanged(color) {
      if (!this.advancedColorMode) {
        this.colors.mainMenuTextColor = this.colors.subMenuTextColor = this.colors.subMenuOpenIconColor = color;
      }
    },
    onSelectedTextColorChanged(color) {
      if (!this.advancedColorMode) {
        this.colors.mainMenuSelectedTextColor = color;
      }
    },
    enableAutoBundleID(enabled) {
      if (enabled) {
        this.onChangeAppname(this.appnameen)
      }
      console.log(this.autoBundleID);
    },
    onChangeAppname(name) {
      if (!name) {
        this.bundleid = '';
        return;
      }
      // if (!/[a-zA-Z0-9]/.test(name)) {
      //   this.autoBundleID = false;
      // }

      if (!this.autoBundleID) return;

      this.bundleid = 'com.' + name;
      this.bundleid = this.bundleid.replace(/^\./, "");
      this.bundleid = this.bundleid.replace(/\.$/, "");
      this.bundleid = this.bundleid.replace(/[^a-zA-Z0-9.]/g, '');
      this.bundleid = this.bundleid.toLowerCase();
    },
    changeAppIcon(image) {
      this.appIcon.setImage(image);
    },
    changeAppHeader(image) {
      this.appHeader.setImage(image);
    },
    changeMainImage(image) {
      this.mainImage.setImage(image);
    },
    changeHeaderMenuButton(id, image, url) {
      this.headerMenuDataList[id].setImage(image);
      this.headerMenuDataList[id].setURL(url);
    },
    changeTileMenuButton(id, image, text, url) {
      this.tileMenuDataList[id].setImage(image);
      this.tileMenuDataList[id].setText(text);
      this.tileMenuDataList[id].setURL(url);
    },
    changeMainMenuButton(id, image, text, url) {
      this.mainMenuDataList[id].setImage(image);
      this.mainMenuDataList[id].setText(text);
      this.mainMenuDataList[id].setURL(url);
    },
    changeSubMenuButton(id, image, text, url) {
      this.subMenuDataList[id].setImage(image);
      this.subMenuDataList[id].setText(text);
      this.subMenuDataList[id].setURL(url);
    },
    changeHeaderMenuButtonCount() {
      if (this.headerMenuButtonCount < 0) {
        this.headerMenuButtonCount = 0;
      }
      if (this.headerMenuDataList.length == this.headerMenuButtonCount) {
        return;
      }
      if (this.headerMenuDataList.length > this.headerMenuButtonCount) {
        let loop = this.headerMenuDataList.length - this.headerMenuButtonCount;
        for (let i = 0; i < loop; ++i) {
          this.headerMenuDataList.pop();
        }
      } else {
        let loop = this.headerMenuButtonCount - this.headerMenuDataList.length;
        for (let i = 0; i < loop; ++i) {
          this.headerMenuDataList.push(new HeaderMenuData());
        }
      }
      for (let i = 0; i < this.headerMenuButtonCount; ++i) {
        this.headerMenuDataList[i].setID(i);
      }
    },
    changeTileMenuButtonCount() {
      if (this.tileMenuColumns <= 0) {
        this.tileMenuColumns = 1;
      }
      if (this.tileMenuRows <= 0) {
        this.tileMenuRows = 1;
      }
      if (this.tileMenuButtonCount < 0) {
        this.tileMenuButtonCount = 0;
      }
      if (this.tileMenuDataList.length == this.tileMenuButtonCount) {
        return;
      }
      if (this.tileMenuDataList.length > this.tileMenuButtonCount) {
        let loop = this.tileMenuDataList.length - this.tileMenuButtonCount;
        for (let i = 0; i < loop; ++i) {
          this.tileMenuDataList.pop();
        }
      } else {
        let loop = this.tileMenuButtonCount - this.tileMenuDataList.length;
        for (let i = 0; i < loop; ++i) {
          this.tileMenuDataList.push(new TileMenuData());
        }
      }
      for (let i = 0; i < this.tileMenuButtonCount; ++i) {
        this.tileMenuDataList[i].setID(i);
      }
    },
    changeMainMenuButtonCount() {
      if (this.mainMenuButtonCount < 0) {
        this.mainMenuButtonCount = 0;
      }
      if (this.mainMenuDataList.length == this.mainMenuButtonCount) {
        return;
      }
      if (this.mainMenuDataList.length > this.mainMenuButtonCount) {
        let loop = this.mainMenuDataList.length - this.mainMenuButtonCount;
        for (let i = 0; i < loop; ++i) {
          this.mainMenuDataList.pop();
        }
      } else {
        let loop = this.mainMenuButtonCount - this.mainMenuDataList.length;
        for (let i = 0; i < loop; ++i) {
          this.mainMenuDataList.push(new MainMenuData());
        }
      }
      for (let i = 0; i < this.mainMenuButtonCount; ++i) {
        this.mainMenuDataList[i].setID(i);
      }
    },
    changeSubMenuButtonCount() {
      if (this.subMenuButtonCount < 0) {
        this.subMenuButtonCount = 0;
      }
      if (this.subMenuDataList.length == this.subMenuButtonCount) {
        return;
      }
      if (this.subMenuDataList.length > this.subMenuButtonCount) {
        let loop = this.subMenuDataList.length - this.subMenuButtonCount;
        for (let i = 0; i < loop; ++i) {
          this.subMenuDataList.pop();
        }
      } else {
        let loop = this.subMenuButtonCount - this.subMenuDataList.length;
        for (let i = 0; i < loop; ++i) {
          this.subMenuDataList.push(new MainMenuData());
        }
      }
      for (let i = 0; i < this.subMenuButtonCount; ++i) {
        this.subMenuDataList[i].setID(i);
      }
    },
    getMainMenuButtonCount() {
      return this.mainMenuButtonCount;
    },
    async restore(file) {
      if (!file) return;
      var jz = new jszip();
      jz.loadAsync(file).then(zip => {
        try {
          zip.file('assets/manifest.json').async('string').then(txt => {
            let json = JSON.parse(txt);

            this.appfullname = json["appfullname"];
            this.appname = json["appname"];
            this.appnameen = json["appnameen"]?.trim();
            this.autoBundleID = json["autoBundleID"];
            this.bundleid = json["bundleid"]?.trim().toLowerCase();
            this.siteurl = json["siteurl"]?.trim();

            this.colors.primaryThemeColor = json.colors["primaryThemeColor"];
            this.colors.secondaryThemeColor = json.colors["secondaryThemeColor"];
            this.colors.primaryTextColor = json.colors["primaryTextColor"];
            this.colors.secondaryTextColor = json.colors["secondaryTextColor"];
            this.colors.selectedTextColor = json.colors["selectedTextColor"];

            if (json.colors['headerBackgroundColor'] == null) { // upgrade colors
              this.colors.headerBackgroundColor     = this.colors.primaryThemeColor;
              this.colors.mainMenuBackgroundColor   = this.colors.subMenuBackgroundColor = this.colors.secondaryThemeColor;
              this.colors.tileMenuTextColor         = this.colors.primaryTextColor;
              this.colors.mainMenuTextColor         = this.colors.subMenuTextColor = this.subMenuOpenIconColor = this.colors.secondaryTextColor;
              this.colors.mainMenuSelectedTextColor = this.colors.selectedTextColor;
              this.colors.tileMenuBackgroundColor1  = '#ffffff';
              this.colors.tileMenuBackgroundColor2  = '#f2f2f2';
            } else {
              this.colors.headerBackgroundColor     = json.colors["headerBackgroundColor"];
              this.colors.mainMenuBackgroundColor   = json.colors["mainMenuBackgroundColor"];
              this.colors.tileMenuBackgroundColor1  = json.colors["tileMenuBackgroundColor1"];
              this.colors.tileMenuBackgroundColor2  = json.colors["tileMenuBackgroundColor2"];
              this.colors.subMenuBackgroundColor    = json.colors["subMenuBackgroundColor"];

              this.colors.tileMenuTextColor         = json.colors["tileMenuTextColor"];
              this.colors.mainMenuTextColor         = json.colors["mainMenuTextColor"];
              this.colors.mainMenuSelectedTextColor = json.colors["mainMenuSelectedTextColor"];
              this.colors.subMenuTextColor          = json.colors["subMenuTextColor"];
              this.colors.subMenuOpenIconColor      = json.colors["subMenuOpenIconColor"];
            }

            this.mainMenuButtonCount = json["mainMenu"].length;
            this.headerMenuButtonCount = json["headerMenu"].length;
            this.tileMenuColumns = json["tileMenuColumns"];
            this.tileMenuRows = json["tileMenuRows"];
            this.subMenuButtonCount = json["subMenu"].length;

            this.changeMainMenuButtonCount();
            this.changeHeaderMenuButtonCount();
            this.changeTileMenuButtonCount();
            this.changeSubMenuButtonCount();

            zip.file("assets/images/appIcon.png")?.async('blob').then(blob => {
              let url = URL.createObjectURL(blob);
              this.changeAppIcon(url);
            });
            zip.file("assets/images/mainImage.png")?.async('blob').then(blob => {
              let url = URL.createObjectURL(blob);
              this.changeMainImage(url);
            });
            zip.file("assets/images/appHeader.png")?.async('blob').then(blob => {
              let url = URL.createObjectURL(blob);
              this.changeAppHeader(url);
            });

            // main menu
            {
              let mainMenu = json["mainMenu"];
              if (mainMenu != null) {
                for (let i = 0; i < mainMenu.length; ++i) {
                  zip.file("assets/images/mainMenu-icon-" + i + ".png")?.async('blob').then(blob => {
                    let url = URL.createObjectURL(blob);
                    this.changeMainMenuButton(i, url, mainMenu[i]["text"], mainMenu[i]["url"]);
                  });
                }
              }
            }

            // sub menu
            {
              let subMenu = json["subMenu"];
              if (subMenu != null) {
                for (let i = 0; i < subMenu.length; ++i) {
                  zip.file("assets/images/subMenu-icon-" + i + ".png")?.async('blob').then(blob => {
                    let url = URL.createObjectURL(blob);
                    this.changeSubMenuButton(i, url, subMenu[i]["text"], subMenu[i]["url"]);
                  });
                }
              }
            }

            // tile menu
            {
              let tileMenu = json["tileMenu"];
              if (tileMenu != null) {
                for (let i = 0; i < tileMenu.length; ++i) {
                  zip.file("assets/images/tileMenu-icon-" + i + ".png")?.async('blob').then(blob => {
                    let url = URL.createObjectURL(blob);
                    this.changeTileMenuButton(i, url, tileMenu[i]["text"], tileMenu[i]["url"]);
                  });
                }
              }
            }

            // header menu
            {
              let headerMenu = json["headerMenu"];
              if (headerMenu != null) {
                for (let i = 0; i < headerMenu.length; ++i) {
                  zip.file("assets/images/headerMenu-icon-" + i + ".png")?.async('blob').then(blob => {
                    let url = URL.createObjectURL(blob);
                    this.changeHeaderMenuButton(i, url, headerMenu[i]["url"]);
                  });
                }
              }
            }
          });
        } catch (e) {
          console.log(e);
        }
      });
    },
    async download(validation = true) {
      if (validation && !this.$refs.mainForm.validate()) {
        this.message = '入力値に誤りがあります。';
        this.$modal.show('modal-message');
        return;
      }

      const IMAGE_PATH = 'assets/images/';
      let json = {};
      let zip = new jszip();
      let imageFolder = zip.folder(IMAGE_PATH);
      let key = '';

      key = 'appfullname';
      json[key] = this.appfullname;

      key = 'appname';
      json[key] = this.appname;

      key = 'appnameen';
      json[key] = this.appnameen?.trim();

      key = 'autoBundleID'
      json[key] = this.autoBundleID;

      key = 'bundleid';
      json[key] = this.bundleid?.trim().toLowerCase();

      key = 'siteurl';
      if (this.siteurl) {
        this.siteurl = this.siteurl.trim();
        if (this.siteurl.substr(-1) != '/') this.siteurl += '/';
      }
      json[key] = this.siteurl;

      key = 'colors';
      json[key] = {
        primaryThemeColor:   this.colors.primaryThemeColor,
        secondaryThemeColor: this.colors.secondaryThemeColor,
        primaryTextColor:    this.colors.primaryTextColor,
        secondaryTextColor:  this.colors.secondaryTextColor,
        selectedTextColor:   this.colors.selectedTextColor,

        headerBackgroundColor     : this.colors.headerBackgroundColor,
        mainMenuBackgroundColor   : this.colors.mainMenuBackgroundColor,
        tileMenuBackgroundColor1  : this.colors.tileMenuBackgroundColor1,
        tileMenuBackgroundColor2  : this.colors.tileMenuBackgroundColor2,
        subMenuBackgroundColor    : this.colors.subMenuBackgroundColor,
        tileMenuTextColor         : this.colors.tileMenuTextColor,
        mainMenuTextColor         : this.colors.mainMenuTextColor,
        mainMenuSelectedTextColor : this.colors.mainMenuSelectedTextColor,
        subMenuTextColor          : this.colors.subMenuTextColor,
        subMenuOpenIconColor      : this.colors.subMenuOpenIconColor,
      };

      key = 'advanced-color-mode';
      json[key] = this.advancedColorMode;

      key = 'mainMenu';
      json[key] = [];
      for (let i = 0; i < this.mainMenuDataList.length; ++i) {
        let name = key + '-icon-' + i + '.png';
        let blob = await (async () => await fetch(this.mainMenuDataList[i].image, { mode: 'no-cors' }).then(r => r.blob()))();
        if (blob && blob.size > 0) {
          json[key].push({
            image: IMAGE_PATH + name,
            text: this.mainMenuDataList[i].text != null ? this.mainMenuDataList[i].text : '',
            url: this.mainMenuDataList[i].url != null ? this.mainMenuDataList[i].url : ''
          });
          imageFolder.file(name, blob, { binary: true });
        }
      }

      key = 'tileMenu';
      json[key] = [];
      json["tileMenuColumns"] = this.tileMenuColumns;
      json["tileMenuRows"] = this.tileMenuRows;
      for (let i = 0; i < this.tileMenuDataList.length; ++i) {
        let name = key + '-icon-' + i + '.png';
        let blob = await (async () => await fetch(this.tileMenuDataList[i].image, { mode: 'no-cors' }).then(r => r.blob()))();
        if (blob && blob.size > 0) {
          json[key].push({
            image: IMAGE_PATH + name,
            text: this.tileMenuDataList[i].text != null ? this.tileMenuDataList[i].text : '',
            url: this.tileMenuDataList[i].url != null ? this.tileMenuDataList[i].url : ''
          });
          imageFolder.file(name, blob, { binary: true });
        }
      }

      key = 'headerMenu';
      json[key] = [];
      for (let i = 0; i < this.headerMenuDataList.length; ++i) {
        let name = key + '-icon-' + i + '.png';
        let blob = await (async () => await fetch(this.headerMenuDataList[i].image, { mode: 'no-cors' }).then(r => r.blob()))();
        if (blob && blob.size > 0) {
          json[key].push({
            image: IMAGE_PATH + name,
            url: this.headerMenuDataList[i].url != null ? this.headerMenuDataList[i].url : ''
          });
          imageFolder.file(name, blob, { binary: true });
        }
      }


      key = 'subMenu';
      json[key] = [];
      for (let i = 0; i < this.subMenuDataList.length; ++i) {
        let name = key + '-icon-' + i + '.png';
        let blob = await (async () => await fetch(this.subMenuDataList[i].image, { mode: 'no-cors' }).then(r => r.blob()))();
        if (blob && blob.size > 0) {
          json[key].push({
            image: IMAGE_PATH + name,
            text: this.subMenuDataList[i].text != null ? this.subMenuDataList[i].text : '',
            url: this.subMenuDataList[i].url != null ? this.subMenuDataList[i].url : ''
          });
          imageFolder.file(name, blob, { binary: true });
        }
      }

      key = 'mainImage';
      {
        let name = key + '.png';
        let blob = await (async () => await fetch(this.mainImage.image, { mode: 'no-cors' }).then(r => r.blob()))();
        if (blob && blob.size > 0) {
          json[key] = {
            image: IMAGE_PATH + name,
          };
          imageFolder.file(name, blob, { binary: true });
        }
      }

      key = 'appHeader';
      {
        let name = key + '.png';
        let blob = await (async () => await fetch(this.appHeader.image, { mode: 'no-cors' }).then(r => r.blob()))();
        if (blob && blob.size > 0) {
          json[key] = {
            image: IMAGE_PATH + name,
          };
          imageFolder.file(name, blob, { binary: true });
        }
      }

      key = 'appIcon';
      {
        let name = key + '.png';
        let blob = await (async () => await fetch(this.appIcon.image, { mode: 'no-cors' }).then(r => r.blob()))();
        if (blob && blob.size > 0) {
          json[key] = {
            image: IMAGE_PATH + name,
          };
          imageFolder.file(name, blob, { binary: true });
        }
      }

      zip.file('assets/manifest.json', JSON.stringify(json));
      zip.generateAsync({type:'blob'}).then(blob => saveAs(blob, this.appname + '.zip'));
    }
  },
  created() {
    this.headerMenuDataList.splice(0);
    this.tileMenuDataList.splice(0);
    this.mainMenuDataList.splice(0);
    this.subMenuDataList.splice(0);
    for (let i = 0; i < this.headerMenuButtonCount; ++i) {
      this.headerMenuDataList.splice(i, 1, new HeaderMenuData());
      this.headerMenuDataList[i].setID(i);
    }
    for (let i = 0; i < this.tileMenuButtonCount; ++i) {
      this.tileMenuDataList.splice(i, 1, new TileMenuData());
      this.tileMenuDataList[i].setID(i);
    }
    for (let i = 0; i < this.mainMenuButtonCount; ++i) {
      this.mainMenuDataList.splice(i, 1, new MainMenuData());
      this.mainMenuDataList[i].setID(i);
    }
    for (let i = 0; i < this.subMenuButtonCount; ++i) {
      this.subMenuDataList.splice(i, 1, new SubMenuData());
      this.subMenuDataList[i].setID(i);
    }
  }
});
</script>

<style lang="scss">
.container {
  max-width: 100%;
}
#preview {
  max-width: 600px;

  #tile-menu {
    color: var(--tile-menu-text-color);
  }
  > div {
    max-width: 500px;
    overflow: hidden;
    margin: auto;
  }
}
#preview-sub-menu > .row p {
  color: var(--sub-menu-text-color);
}
#option > .container { 
  display: flex;
}
#app-icon .v-image {
  border-radius: 24px;
  margin: auto;
  border: 1px solid #aaa;
}
#main-menu {
  justify-content: space-around !important;
  background-color: var(--main-menu-background-color);
}
#main-menu > div:nth-child(1) p {
  color: var(--main-menu-selected-text-color);
}
#main-menu > div p {
  color: var(--main-menu-text-color);
}
.color-settings-toggle > div > div {
  flex-direction: column-reverse;
}
</style>

<style scoped lang="scss">
h3, h4 {
  width: 100%;
}
label { 
  width: 100%;
}
hr {
  width: 100%;
  margin: 16px 0;
}
.v-color-picker {
  margin: auto;
}
.v-label {
  display: block;
  text-align: center;
}
.color-flex-box {
  .v-label {
    width: 230px;
  }
}
#app-preview > .container {
  display: flex;
}
#preview-main {
  > .preview-app-header {
    position: relative;
    background-color: var(--header-background-color);
    height: 60px;
    
    > .preview-app-menu-toggle-button {
      position: absolute;
      top: 0;
      left: 0;

      .v-icon {
        color: var(--sub-menu-open-icon-color);
      }
    }
  }
}
#preview-sub-menu {
  padding: 0;

  > .bg-overlay {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.667);
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  > .row {
    margin: 0;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    min-height: 100%;
    min-width: 300px;
    height: 950px;
    overflow: auto;
    background: var(--sub-menu-background-color);
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.color-flex-box {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
  width: 100%;
}
.modal-message-force-download-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  text-align: center;
  margin: 0;
  padding: 4px 0;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  cursor: pointer;
  background: #eee;
  transition: .3s;
}
.modal-message-close-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  text-align: center;
  margin: 0;
  padding: 4px 0;
  border-top: 1px solid #aaa;
  cursor: pointer;
  background: #eee;
  transition: .3s;
}
.modal-message-close-button:hover,
.modal-message-force-download-button:hover {
  background: #ddd;
}
.modal-message-close-button:active,
.modal-message-force-download-button:active {
  background: #aaa;
}
.color-settings-toggle {
  margin:0;
  position:absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
@media (max-width: 1264px) {
  .container {
    display: block !important;
  }
}
@media (max-width: 600px) {
  .color-flex-box {
    display: block;
    margin: auto;
  }
}
</style>
