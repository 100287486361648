<template>
  <div>
    <v-img
      :src="image.image"
      :min-width="width"
      :min-height="height"
      :max-width="width"
      :max-height="height"
      :background-color="backgroundcolor"
      v-ripple
      @click="click"
      style="cursor: pointer"
      :style="{ cursor: 'pointer', backgroundColor: backgroundcolor }"
    ></v-img>
    <modal :name="'modal-image-' + name" :adaptive="true" :width="720" :max-width="720" height="auto">
      <div class="pa-4 ma-4">
        <v-file-input accept="image/*" :label="'画像 (' + image.width + 'x' + image.height + ')'" @change="changeImage" :value="file"></v-file-input>
        <p style="color:red;">{{ warningMessage }}</p>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import loadImage from 'blueimp-load-image';

class ImageData {
  width;
  height;
  image;

  constructor(width, height) {
    this.width = width;
    this.height = height;
    this.image = '';
  }
  setImage(image) { this.image = image; }
}

export default Vue.extend({
  name: "ImageButton",
  props: {
    defaultwidth: {
      type: Number,
      default: 100,
    },
    defaultheight: {
      type: Number,
      default: 100,
    },
    name: {
      type: String,
      default: ''
    },
    image: {
      default: new ImageData(128, 128)
    },
    onchanged: {
      type: Function,
    },
    backgroundcolor: {
      type: String,
      default: 'transparent'
    }
  },
  data: () => ({
    width: 1,
    height: 1,
    targetwidth: 1,
    targetheight: 1,
    warningMessage: '',
    file: null
  }),
  methods: {
    click() {
      this.$modal.show('modal-image-' + this.name);
    },
    changeImage(file) {
      this.file = file;
      loadImage(
        file,
        async (canvas) => {
          let a = canvas.width / canvas.height;
          let b = this.targetwidth / this.targetheight;
          let abs = Math.abs(a - b);
          console.log(a);
          console.log(b);
          console.log(abs);
          if (abs == 0) {
            this.warningMessage = '';
          } else if (abs > 0.1) {
            this.warningMessage = 'アスペクト比が大幅に異なる または 指定されているサイズ(' + this.targetwidth + 'x' + this.targetheight + ')の画像ではないため、実際のアプリで表示がおかしくなる可能性があります。';
          } else {
            this.warningMessage = '';
          }
          const data = canvas.toDataURL(file.type);
          const bin = atob(data.replace(/^.*,/, ''));
          const buffer = new Uint8Array(bin.length);
          for (let i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
          }
          const blob =  new Blob([buffer.buffer], {
            type: file.type ? file.type : 'image/png'
          });
          const url = window.URL.createObjectURL(blob);
          this.onchanged(url);
        },
        { canvas: true }
      );
    },
  },
  mounted() {
    this.width  = this.defaultwidth;
    this.height = this.defaultheight;
    this.targetwidth = this.image.width;
    this.targetheight = this.image.height;
  },
});
</script>
